<template>
  <div class="ban">
    <div class="prodamation">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>公告</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked === item.id ? 'checked' : ''"
            v-for="item in NoticeInfo"
            :key="item.id"
            @click="updeInit"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <!-- 公告列表 -->
      <div class="card-right" title="点击查看详情" v-show="showCard === 1">
        <div
          class="card-cont"
          v-for="item in noticeList"
          :key="item.id"
          @click="details(item)"
        >
          <div class="cont-left">
            <img :src="item.extendColumn1" alt="" />
          </div>
          <div class="cont-right">
            <h4>{{ item.articleTitle }}</h4>
            <!-- <div class="count" v-html="item.articleContent"></div> -->
            <div class="btm">
              <div class="top">
                <p>发布时间:{{ handleTime(item.createTime) }}</p>
                <p>结束时间:{{ item.endtime }}</p>
              </div>
              <p class="publisher">发布人:{{ item.publishername }}</p>
            </div>
          </div>
        </div>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="noticeFrom.currentPage"
          :page-sizes="[3, 5, 10, 15]"
          :page-size="noticeFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 公告详情 -->
      <div class="card-right" v-show="showCard === 2">
        <h3>{{ noticeInfos.articleTitle }}</h3>
        <div class="count" v-html="noticeInfos.articleContent">
          <!-- {{ noticeInfos.articleContent }} -->
        </div>
        <div class="btm">
          <div class="top">
            <p v-if="showCard === 2">
              发布时间:{{ handleTime(noticeInfos.createTime) }}
            </p>
            <p>结束时间:{{ noticeInfos.endtime }}</p>
          </div>
          <p class="publisher">发布人:{{ noticeInfos.publishername }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/index/index";
export default {
  name: "Proclamation",
  data() {
    return {
      showCard: 1,
      noticeFrom: {
        currentPage: 1,
        pageSize: 3,
      },
      total: 0,
      checked: 0,
      NoticeInfo: [
        {
          id: 0,
          text: "全部公告",
        },
      ],
      noticeList: [],
      noticeId: "",
      noticeInfos: {},
    };
  },
  created() {
    // console.log("获取首页传来的数据", this.$route.params); // 进行公告的单一 和 全部展示
    let obj = JSON.stringify(this.$route.params);
    if (obj === "{}") {
      // console.log("页面刷新");
      this.init();
    } else {
      let info = this.$route.params.id;
      console.log("", info);
      this.noticeId = info;
      this.init();
    }
    // console.log("公告id", this.noticeId);
  },
  methods: {
    // changes(val){
    //   return
    // }
    // 点击查看公告详情
    details(item) {
      this.showCard = 2;
      this.noticeInfos = item;
      console.log("公告详情", this.noticeInfos);
    },
    handleSizeChange(val) {
      this.noticeFrom.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.noticeFrom.currentPage = val;
      this.init();
    },
    // 全部公告
    updeInit() {
      this.noticeId = "";
      this.showCard = 1;
      this.init();
    },
    // 处理发布时间
    handleTime(time) {
      let times = time.substr(0, 10);
      return times;
    },
    // 获取公告列表
    init() {
      api
        .getArticle(this.noticeFrom.currentPage, this.noticeFrom.pageSize)
        .then((res) => {
          console.log("公告列表", res);
          if (res.success) {
            this.total = res.data.total;
            // 首页跳转筛选数据
            if (this.noticeId !== "" && this.noticeId !== "more") {
              this.noticeList = res.data.list.filter(
                (el) => el.id === this.noticeId
              );
            } else {
              // 页面刷新返回全部数据
              let newArr = res.data.list.filter(
                (el) => el.recommend === 1 && el.articleState
              );
              console.log("筛选过后的数组", newArr);
              this.noticeList = newArr;
            }
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ban {
  background-color: #f5f5f5;
}
.prodamation {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;
      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        background-color: #fff;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: none;
      }
      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 910px;
    background-color: #fff;
    padding: 30px;
    h3 {
      text-align: center;
    }
    .card-cont {
      display: flex;
      justify-content: space-around;
      box-shadow: 0px 0px 10px #999;
      margin-bottom: 20px;
      .cont-left {
        max-width: 200px;
        max-height: 300px;
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          max-width: 200px;
          max-height: 300px;
        }
      }
      .cont-right {
        width: 660px;
        padding: 15px;
        p {
          margin-bottom: 10px;
        }
        .count {
          margin: 10px 0;
          width: 100%;
          text-indent: 2em;
          line-height: 25px;
          word-wrap: break-word;
          word-break: break-all;
        }
        .btm {
          .top {
            display: flex;
            justify-content: space-between;
            color: #808080;
          }
          .publisher {
            float: right;
            color: #00abed;
          }
        }
      }
    }
  }
  .card-right {
    .count {
      margin: 10px 0;
      width: 100%;
      text-indent: 2em;
      line-height: 25px;
      word-wrap: break-word;
      word-break: break-all;
    }
    .btm {
      .top {
        display: flex;
        justify-content: space-between;
        color: #808080;
        margin-bottom: 10px;
      }
      .publisher {
        float: right;
        color: #00abed;
      }
    }
  }
}
</style>
